import { template as template_b303de1abf1742d4aaf8aa5cd76e75cf } from "@ember/template-compiler";
const FKControlMenuContainer = template_b303de1abf1742d4aaf8aa5cd76e75cf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
