import { template as template_a4ee7caee728416ab3aa493f8043cca3 } from "@ember/template-compiler";
const FKLabel = template_a4ee7caee728416ab3aa493f8043cca3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
